import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"



//On importe les composants
import Header from '../components/header'
import Adresse from '../components/adresse'
import Footer from '../components/footer'
import BandeauContact from '../components/bandeau-contact'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le CSS
import'../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'


export default function StupefiantVolant() {


    const Sommaire = [
        { titre: "Conduire sous l'emprise de stupéfiants", ancre: "conduire", lien: "#conduire" },
        { titre: "Quelles sont les sanctions encourues ?", ancre: "sanction", lien: "#sanction" },
        { titre: "Comment contester, quels sont les vices de procédure ?", ancre: "contester", lien: "#contester" }
    ]


    return (
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Stupéfiants au Volant : de quoi parle-ton ?</title>
                <meta name="description" content="Souvent improprement qualifiée de « conduite sous produits stupéfiants », la réglementation de la drogue au volant est en réalité toute autre. " />
                <html lang="fr" />
                <link rel="icon" href={Favicon} />


            </Helmet>

            <Header />

            <div className="BackGrey">
                <div className="BandeauMain">

                    <div className="BandeauPageContainer">

                        <h1>Stupéfiants au Volant</h1>
                        <h2 align="center">De quoi parle-ton ?</h2>

                    </div>

                </div>

            </div>


            <div className="AccrochePageMain">

                <div className="AccrochePageContainer">

                    <div className="AccrochePageItem">

                        <StaticImage src="../images/portrait-debrabant.jpg" alt="thierry debrabant avocat" placeholder="blurred" layout="fixed" width={200} height={200} />

                    </div>

                    <div className="AccrochePageItem">

                        <h4 align="center">Maître Thierry DEBRABANT, Avocat</h4>

                        <p align="justify">
                            “Il est donc tout à fait possible d’être poursuivi et condamné pour l’infraction de conduite après usage de produits stupéfiants même si la consommation de produits remonte à 1 voire 2 jours avant le contrôle.”
                        </p>

                        <div className="AccrochePageItemLink">
                            <Link to="#contact">Contacter</Link>
                        </div>

                    </div>


                </div>
            </div>


            <div className="SommairePage">
                <h2>SOMMAIRE</h2>
                <ul>
                    {
                        Sommaire.map((item, i) => {
                            return (
                                <li key={i}><a href={item.lien}>{item.titre}</a></li>
                            )
                        })
                    }

                </ul>
            </div>

            <div className="TableauMain">

                <h3 align="center" id={Sommaire[0].ancre}>{Sommaire[0].titre}</h3>
                <div className="Contenu">
                    <p align="justify">Souvent improprement qualifiée de <b>« conduite sous produits stupéfiants »</b>, la réglementation de la drogue au volant est en réalité toute autre.</p>

                    <br />

                    <p align="justify">Contrairement au délit de conduite en état alcoolique qui réprime le fait d’être « sous l’empire d’un état alcoolique », <b>l’article L 235-1 du Code de la Route</b> dispose :</p>

                    <p align="justify"><i>« Toute personne qui conduit un véhicule ou qui accompagne un élève conducteur <u>alors qu'il résulte d'une analyse sanguine ou salivaire qu'elle a fait usage de substances ou plantes classées comme stupéfiants</u> est punie de deux ans d'emprisonnement et de 4 500 euros d'amende. »</i></p>

                    <br />

                    <p align="justify">Les conséquences de cette différence de rédaction en apparence anodine sont <b>nombreuses et importantes</b>.</p>

                    <p align="justify">D’abord, peu importe aux yeux que la loi que le conducteur a consommé de la drogue peu de temps avant de prendre le volant <b>ou au contraire il y a plusieurs jours</b>.</p>

                    <p align="justify"><u>Une analyse salivaire ou sanguine positive suffit</u> pour la caractérisation de l’infraction, même si une quantité très faible de drogue, synonyme d’une consommation qui date de plus de 36 heures, est retrouvée dans l’organisme.</p>

                    <p align="justify">Il est donc tout à fait possible d’être <b>poursuivi et condamné</b> pour l’infraction de conduite après usage de produits stupéfiants même si la consommation de produits remonte à 1 voire 2 jours avant le contrôle.</p>

                    <p align="justify">Ensuite, <b>peu importent également les déclarations du conducteur</b>, ainsi la condamnation est encourue même si ce dernier affirme n’avoir consommé aucun produit stupéfiant, et à l’inverse il est tout à fait possible d’être relaxé, c’est à dire innocenté par le tribunal en dépit de déclarations accablantes du type <i>« je fume 3 joints de cannabis tous les soirs depuis 4 ans »</i>, <b>si les analyses ressortent négatives</b>, ou si la défense parvient à faire annuler la procédure ou les analyses, nous y reviendrons ci-après.</p>

                </div>


                <h3 align="center" id={Sommaire[1].ancre}>{Sommaire[1].titre}</h3>
                <div className="Contenu">
                    <p align="justify">Le fait de conduire ou accompagner un élève conducteur après avoir fait usage de stupéfiants est <b>très lourdement sanctionné</b> par le code de la route avec des peines similaires à la conduite sous l’empire d’un état alcoolique :</p>
                    <ul>
                        <li>2 ans d’emprisonnement maximum,</li>
                        <li>4.500 € d’amende délictuelle,</li>
                        <li>la suspension du permis de conduire pour une durée de 36 mois maximum,</li>
                        <li>l’annulation du permis de conduire avec interdiction de le repasser pour une durée de 3 ans maximum.</li>
                    </ul>


                </div>


                <h3 align="center" id={Sommaire[2].ancre}>{Sommaire[2].titre}</h3>
                <div className="Contenu">
                    <p align="justify"><b>De nombreux arguments permettent de faire annuler</b> tout ou partie de la procédure, d’être relaxé (c’est à dire déclaré non coupable) par le tribunal, ou encore d’obtenir le classement sans suite.</p>

                    <p align="justify">Vous trouverez sur la page d’accueil dans la rubrique <a href="/">nos résultats</a> <b>une sélection récente de décisions obtenues par le cabinet</b>.</p>

                    <p align="justify">A chaque fois, le client, généralement <u>très mal renseigné par les forces de l’ordre</u>, pensait qu’il était impossible ou inutile de se défendre.</p>

                    <p align="justify">C’est précisément parce que les policiers et gendarmes connaissent très mal les textes qu’ils sont pourtant chargés d’appliquer que <b>les vices et nullités de procédure sont aussi nombreux</b>.</p>

                    <br/>
                    <p align="justify">En synthèse, <u>les vices de procédure ou les arguments de fond relèvent principalement </u>:</p>

                    <ul>
                        <li>de l’absence de notification des résultats de l’analyse salivaire ou sanguine,</li>
                        <li>de l’absence d’information, ou d’une mauvaise information sur la possibilité d’effectuer une seconde analyse,</li>
                        <li>d’un taux insuffisant dans le sang et/ou la salive pour permettre les poursuites.</li>
                    </ul>

                    <br/>


                    <p align="justify"><b>L’article R235-11 du Code de la Route</b> dispose en effet que pour bénéficier d’une seconde analyse sanguine au moment de la notification des résultats, le conducteur doit avoir pensé à solliciter <b>une prise de sang le jour même de son interpellation</b>.</p>

                    <p align="justify">On comprend immédiatement que cette règle a été conçue pour que les demandes de secondes analyses sanguines <u>soient réduites au minimum</u>.</p>

                    <p align="justify">En effet, la plupart des conducteurs vont renoncer spontanément à cette possibilité offerte par la loi car ils ont tout simplement <b>hâte de rentrer chez eux</b>.</p>

                    <br />
                    <p align="justify">Pour ceux qui ont quand même l’intuition qu’il faut mieux demander la prise de sang en plus du prélèvement salivaire pour pouvoir préserver leurs droits, <u>les forces de l’ordre ne vont généralement pas hésiter à les en dissuader au moyen d’arguments souvent fallacieux</u> :</p>

                    <ul>
                        <li>la prise de sang ne changera rien aux résultats : <b>c’est faux</b>, dans de nombreux dossiers, c’est justement la prise de sang qui permet de contester les résultats du test de dépistage,</li>
                        <li>si vous demandez la prise de sang, on sera obligé de vous placer en garde à vue : <b>c’est évidemment tout aussi mensonger</b>, voire à minima une faute disciplinaire pour celui qui formule une telle menace,</li>
                        <li>vous allez perdre beaucoup de temps et vous allez rentrer chez vous beaucoup plus tard : <b>c’est seulement partiellement vrai</b>, car en général, la prise de sang se fait en quelques minutes, mais si vous êtes interpellé en pleine nuit, il faudra effectivement le temps de trouver un professionnel qualifié pour effectuer la prise de sang,</li>
                        <li>la prise de sang est à votre charge et vous sera facturée 500 € : <b>c’est totalement faux</b>, et là encore cela expose le gendarme qui a délibérément menti au conducteur dans un de mes dossiers récents à des poursuites disciplinaires, tout en me permettant de faire annuler toute la procédure.</li>
                    </ul>


                    <br />

                    <p align="justify">Vous l’aurez compris, <b>renoncer à la prise de sang est une très mauvaise idée</b>, car cela diminue drastiquement (sans heureusement les supprimer totalement) les possibilités de contestations ultérieures.</p>

                    <p align="justify">C’est précisément pour cette raison que les forces de l’ordre s’arrangent généralement pour que le conducteur renonce à cette prise de sang.</p>

                    <p align="justify">J’ai d’ailleurs été saisi d’un dossier au début de l’année 2021 dans lequel <b>la signature sur le formulaire par lequel mon client était censé avoir renoncé à la seconde analyse n’était pas la sienne !</b></p>

                    <p align="justify">J’ai demandé au tribunal une expertise judiciaire en écritures afin d’en être certain, c’est vraisemblablement un gendarme qui a voulu <i>« sauver »</i> sa procédure en signant à la place de mon client.</p>

                    <p align="justify">Je ne manquerai pas de publier la décision du Tribunal dans la partie <i>« nos résultats »</i> dès que la décision aura été rendue en décembre 2021.</p>

                    <p align="justify"><b>Les policiers et gendarmes disposent à cet égard d’une arme redoutable</b>, à savoir un formulaire déjà rédigé que le conducteur n’a qu’à signer sans avoir le temps de le lire en raison de la quantité d’informations qui y figure.</p>


                    <br/>
                    <p align="justify">Voici à quoi ressemble ce formulaire : <a href="/formulaire-stups.pdf">Voir le formulaire</a></p>

                    <br /><br />

                    <p align="justify">Si on vous soumet un tel document à la signature, <b>lisez le attentivement avant de le signer</b>, et en tout état de cause, cochez les cases qui vous permettent de bénéficier de la seconde analyse de contrôle !</p>

                    <br/>

                    <p align="justify">Enfin, contrairement à une idée reçue, <u>il existe bien des taux</u>, c’est à dire des seuls minima de détection qui sont prévus par l’arrêté du 13 décembre 2016 :</p>

                    <ul>
                        <li>1 ng/ml de salive pour le THC (cannabis),</li>
                        <li>10 ng/ml de salive pour les amphétamines,</li>
                        <li>10 ng/ml de salive pour la cocaïne ou dérivés,</li>
                        <li>10 ng/ml de salive pour les opiacés/morphine.</li>
                    </ul>
                    <br />

                    <p align="justify">Il y a aussi des <u>seuils minimaux</u> en cas d’analyse sanguine :</p>

                    <ul>
                        <li>0,5 ng/ml de sang pour le THC (cannabis),</li>
                        <li>10 ng/ml de sang pour les amphétamines,</li>
                        <li>10 ng/ml de sang pour la cocaïne ou dérivés,</li>
                        <li>10 ng/ml de sang pour les opiacés/morphine.</li>
                    </ul>

                    <br />



                    <p align="justify"><b>Seule l’étude de votre procédure par un avocat compétent en matière de code de la route</b> peut permettre d’exploiter efficacement ces différents arguments. </p>

                    <BandeauContact />
                </div>

            </div>


            <Adresse />

            <Footer />


        </HelmetProvider >
    )
}
